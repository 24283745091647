<template>
    <i class="fas fa-chevron-up"></i>
</template>

<script>


export default {
   
    props: {
     
    },
    data() {
      return {
        
      } 
    },
    methods: {
     
    },
    watch: {
       
    },
};
</script>
